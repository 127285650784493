exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-jsx": () => import("./../../../src/pages/admin.jsx" /* webpackChunkName: "component---src-pages-admin-jsx" */),
  "component---src-pages-advocate-details-jsx": () => import("./../../../src/pages/advocateDetails.jsx" /* webpackChunkName: "component---src-pages-advocate-details-jsx" */),
  "component---src-pages-advocate-signup-jsx": () => import("./../../../src/pages/advocate-signup.jsx" /* webpackChunkName: "component---src-pages-advocate-signup-jsx" */),
  "component---src-pages-appointment-jsx": () => import("./../../../src/pages/appointment.jsx" /* webpackChunkName: "component---src-pages-appointment-jsx" */),
  "component---src-pages-athlete-advocate-jsx": () => import("./../../../src/pages/athleteAdvocate.jsx" /* webpackChunkName: "component---src-pages-athlete-advocate-jsx" */),
  "component---src-pages-blog-detail-jsx": () => import("./../../../src/pages/blogDetail.jsx" /* webpackChunkName: "component---src-pages-blog-detail-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pacific-jsx": () => import("./../../../src/pages/pacific.jsx" /* webpackChunkName: "component---src-pages-pacific-jsx" */),
  "component---src-pages-payment-jsx": () => import("./../../../src/pages/payment.jsx" /* webpackChunkName: "component---src-pages-payment-jsx" */),
  "component---src-pages-photos-videos-jsx": () => import("./../../../src/pages/photosVideos.jsx" /* webpackChunkName: "component---src-pages-photos-videos-jsx" */),
  "component---src-pages-profile-details-jsx": () => import("./../../../src/pages/profileDetails.jsx" /* webpackChunkName: "component---src-pages-profile-details-jsx" */),
  "component---src-pages-protostar-jsx": () => import("./../../../src/pages/protostar.jsx" /* webpackChunkName: "component---src-pages-protostar-jsx" */),
  "component---src-pages-provider-login-jsx": () => import("./../../../src/pages/providerLogin.jsx" /* webpackChunkName: "component---src-pages-provider-login-jsx" */),
  "component---src-pages-provider-profile-jsx": () => import("./../../../src/pages/providerProfile.jsx" /* webpackChunkName: "component---src-pages-provider-profile-jsx" */),
  "component---src-pages-provider-signup-jsx": () => import("./../../../src/pages/provider-signup.jsx" /* webpackChunkName: "component---src-pages-provider-signup-jsx" */),
  "component---src-pages-providers-jsx": () => import("./../../../src/pages/providers.jsx" /* webpackChunkName: "component---src-pages-providers-jsx" */),
  "component---src-pages-settings-jsx": () => import("./../../../src/pages/settings.jsx" /* webpackChunkName: "component---src-pages-settings-jsx" */),
  "component---src-pages-socal-jsx": () => import("./../../../src/pages/socal.jsx" /* webpackChunkName: "component---src-pages-socal-jsx" */),
  "component---src-pages-userprofile-jsx": () => import("./../../../src/pages/userprofile.jsx" /* webpackChunkName: "component---src-pages-userprofile-jsx" */),
  "component---src-pages-view-profile-jsx": () => import("./../../../src/pages/viewProfile.jsx" /* webpackChunkName: "component---src-pages-view-profile-jsx" */)
}

